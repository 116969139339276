// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  h1 {
    text-align: center;
    margin-top: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  .error {
    color: red;
    font-size: 0.9rem;
  }
  
  .success-message {
    background-color: #d4edda;
    padding: 10px;
    border-radius: 4px;
    color: #155724;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .submit-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .submit-btn:hover {
    background-color: #218838;
  }
  `, "",{"version":3,"sources":["webpack://./src/UserForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;IACX,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".form-container {\n    max-width: 600px;\n    margin: 20px auto;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n  }\n  \n  h1 {\n    text-align: center;\n    margin-top: 20px;\n    color: #333;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  \n  .form-group label {\n    display: block;\n    font-size: 1rem;\n    font-weight: bold;\n    margin-bottom: 5px;\n  }\n  \n  .form-group input,\n  .form-group textarea {\n    width: 100%;\n    padding: 10px;\n    font-size: 1rem;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n  }\n  \n  .form-group textarea {\n    resize: vertical;\n  }\n  \n  .error {\n    color: red;\n    font-size: 0.9rem;\n  }\n  \n  .success-message {\n    background-color: #d4edda;\n    padding: 10px;\n    border-radius: 4px;\n    color: #155724;\n    margin-bottom: 10px;\n    text-align: center;\n  }\n  \n  .submit-btn {\n    background-color: #28a745;\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 1rem;\n  }\n  \n  .submit-btn:hover {\n    background-color: #218838;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
